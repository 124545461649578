// There are certain styles defined in bootstrap.css which get 
// overridden by the more-specific styles in jquery-ui. This file
// should contain rules that intentionally override certain jquery-ui
// selectors to restore the rules that boostrap wants to have.


.ui-widget button.btn {
    font-family: inherit;
    font-size: 15px;
}

// The above rule messes with bootstrap's button sizes, so we need to let those get re-applied.
.ui-widget button.btn.btn-lg:extend(.btn-lg){}
.ui-widget button.btn.btn-sm:extend(.btn-sm){}
.ui-widget button.btn.btn-xs:extend(.btn-xs){}

//a, a:visited, a:active,
.ui-widget-content a,
.ui-widget-content a:active,
.ui-widget-content a:visited 
.ui-widget-content a,
.ui-widget-content a:active,
.ui-widget-content a:visited 
{
    &.btn-primary:extend(.btn-primary)
    {
        &:hover:extend(.btn-primary:hover){}
        &:focus:extend(.btn-primary:focus){}
        &:active:extend(.btn-primary:active){}
    }
    &.btn-default:extend(.btn-default)
    {
        &:hover:extend(.btn-default:hover){}
        &:focus:extend(.btn-default:focus){}
        &:active:extend(.btn-default:active){}
    }
    &.btn-danger:extend(.btn-danger)
    {
        &:hover:extend(.btn-danger:hover){}
        &:focus:extend(.btn-danger:focus){}
        &:active:extend(.btn-danger:active){}
    }
    &.btn-danger:extend(.btn-success)
    {
        &:hover:extend(.btn-success:hover){}
        &:focus:extend(.btn-success:focus){}
        &:active:extend(.btn-success:active){}
    }
    &.btn-danger:extend(.btn-info)
    {
        &:hover:extend(.btn-info:hover){}
        &:focus:extend(.btn-info:focus){}
        &:active:extend(.btn-info:active){}
    }
    &.btn-danger:extend(.btn-warning)
    {
        &:hover:extend(.btn-warning:hover){}
        &:focus:extend(.btn-warning:focus){}
        &:active:extend(.btn-warning:active){}
    }
    &.btn-danger:extend(.btn-link)
    {
        &:hover:extend(.btn-link:hover){}
        &:focus:extend(.btn-link:focus){}
        &:active:extend(.btn-link:active){}
    }
} 

.ui-widget-content a {
    &.btn:hover{
        text-decoration: none;
    }
}

.ui-icon-closethick {
    background-position: -94px -128px
}
